import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { addPatient, getAllOrganizations, getAllHealthProviderByUuid } from '../../api';
import AddPatientGeneralInfo from './AddPatientGeneralInfo';
import AddPatientAdditionalInfo from './AddPatientAdditionalInfo';

const AddPatient = () => {
	const { register, handleSubmit, errors, reset, getValues } = useForm();
	const [patient, setPatient] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [isShowAdditionalInfo, setIsShowAddidtionalInfo] = useState(false);
	const [healthProviderList, setHealthProviderList] = useState([]);
	const [organizationList, setOrganizationList] = useState([]);
	const [deviceData, setDeviceData] = useState({
		isWifiAvailable: true,
		availableDevice: 'iPhone',
		provideSmartScale: true,
		provideECG: true,
	});
	const [otherMedicalConditions, setOtherMedicalConditions] = useState({
		chronicKidneyDisease: null,
		chronicObstructivePulmonaryDisease: null,
		congestiveHeartFailure: null,
		covid19: null,
		diabetes: null,
		insulin: null,
		hyperlipidemia: null,
		hypertension: null,
		osteoarthritis: null,
		osteoperosis: null,
		isFallen: null,
		dizziness: null,
		assistiveWalking: null,
	});

	useEffect(async () => {
		try {
			const { data: organizationList } = await getAllOrganizations();
			setOrganizationList(organizationList);
			await getOrganizationHeatlhProvidersList(organizationList[0].uuid);
		} catch (error) {}
	}, []);

	const organizationChanged = (event) => {
		const organizationUuid = getValues('organizationUuid');
		getOrganizationHeatlhProvidersList(organizationUuid);
	};

	const getOrganizationHeatlhProvidersList = async (uuid) => {
		const { data: healthProviders } = await getAllHealthProviderByUuid(uuid);
		setHealthProviderList(healthProviders);
	};

	const resetForm = () => {
		reset();
		setIsShowAddidtionalInfo(false);
		setDeviceData({ isWifiAvailable: true, availableDevice: 'iPhone', provideSmartScale: true, provideECG: true });
		setOtherMedicalConditions({
			chronicKidneyDisease: null,
			chronicObstructivePulmonaryDisease: null,
			congestiveHeartFailure: null,
			covid19: null,
			diabetes: null,
			hyperlipidemia: null,
			hypertension: null,
			osteoarthritis: null,
			osteoperosis: null,
		});
	};

	const onSubmit = async (data) => {
		if (isShowAdditionalInfo) {
			setIsLoading(true);
			try {
				const careTakerData = {
					has_caretaker: false,
					caretaker_relation: '',
					caretaker_firstname: '',
					caretaker_lastname: '',
					caretaker_phone: '',
					caretaker_email: '',
				};
				const emergencyData = {
					has_emergency_contact: false,
					contact_relation: '',
					contact_firstname: '',
					contact_lastname: '',
					contact_phone: '',
					contact_email: '',
				};
				const response = await addPatient({
					body: {
						...data,
						...patient,
						...deviceData,
						...otherMedicalConditions,
						...careTakerData,
						...emergencyData,
					},
				});
				setPatient({ ...data, ...patient, ...deviceData, ...otherMedicalConditions });
				setIsLoading(false);
				resetForm();
				alert(response.message);
			} catch (error) {
				setIsLoading(false);
				alert(error.message || 'Something went wrong. Try again later!');
			}
		} else {
			setPatient({ ...patient, ...data });
			setIsShowAddidtionalInfo(true);
		}
	};

	const showForm = () => {
		if (isShowAdditionalInfo) {
			return (
				<AddPatientAdditionalInfo
					register={register}
					errors={errors}
					ErrorMessage={ErrorMessage}
					FormFieldErrorMessage={FormFieldErrorMessage}
					otherMedicalConditions={otherMedicalConditions}
					setOtherMedicalConditions={setOtherMedicalConditions}
				></AddPatientAdditionalInfo>
			);
		}
		return (
			<AddPatientGeneralInfo
				register={register}
				errors={errors}
				healthProviderList={healthProviderList}
				organizationList={organizationList}
				organizationChanged={organizationChanged}
				ErrorMessage={ErrorMessage}
				FormFieldErrorMessage={FormFieldErrorMessage}
				deviceData={deviceData}
				setDeviceData={setDeviceData}
			></AddPatientGeneralInfo>
		);
	};

	const formButton = () => {
		if (isShowAdditionalInfo) {
			return (
				<button
					className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
					type="submit"
					disabled={isLoading}
				>
					Add Patient
				</button>
			);
		}

		return (
			<button
				className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
				type="submit"
				disabled={isLoading}
			>
				Next
			</button>
		);
	};

	return (
		<div>
			<div className="container mx-auto px-4 h-full">
				<div className="flex content-center items-center justify-center h-full">
					<div className="px-4 patient-form-width">
						<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
							<div className="flex-auto px-4 lg:px-10 py-10 pt-6">
								<form onSubmit={handleSubmit(onSubmit)}>
									{showForm()}
									<div className="text-center mt-6">{formButton()}</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddPatient;
