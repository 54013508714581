import { getAdminUsersList, getAllOrganizations, updateAdminUser } from '../../api';
import React, { useState, useEffect } from 'react';

import './User.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';

const OrganizationsListTable = ({ user }) => {
	const [organizationsList, setOrganizationsList] = useState([]);

	const fetchData = async () => {
		try {
			const { data } = await getAllOrganizations();

			setOrganizationsList(data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		console.log(organizationsList);
	}, [organizationsList]);

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="flex flex-wrap">
				<h1>Organizations List</h1>

				<DataTable
					keyField="userId"
					columns={[
						{
							name: 'Name',
							sortable: true,
							selector: 'name',
							grow: 2,
						},
						{
							name: 'Address 1',
							sortable: true,
							selector: 'address1',
							grow: 2,
						},
						{
							name: 'Address 2',
							sortable: true,
							selector: 'address2',
							grow: 2,
						},
						{
							name: 'City',
							sortable: true,
							selector: 'city',
						},
						{
							name: 'State',
							sortable: true,
							selector: 'state',
						},
						{
							name: 'Zip',
							sortable: true,
							selector: 'zip',
						},
						{
							name: 'Tax ID',
							sortable: true,
							selector: 'taxId',
						},
						{
							name: 'Domain',
							sortable: true,
							selector: 'domain',
							grow: 1.5,
						},
						{
							name: 'Contact First Name',
							sortable: true,
							selector: 'contactFirstName',
						},
						{
							name: 'Contact Last Name',
							sortable: true,
							selector: 'contactLastName',
						},
						{
							name: 'Contact Role',
							sortable: true,
							selector: 'contactRole',
							grow: 1.5,
						},
						{
							name: 'Contact Email',
							sortable: true,
							selector: 'contactEmail',
							grow: 2,
						},
						{
							name: 'Contact Phone Number',
							sortable: true,
							selector: 'contactPhoneNumber',
							grow: 2,
						},
						{ name: 'Created At', sortable: true, selector: 'created_at', grow: 1.5 },
						{ name: 'Updated At', sortable: true, selector: 'updated_at', grow: 1.5 },
					]}
					data={organizationsList || []}
					// customStyles={dataTableStyles(getCurrentLanguage())}
					pagination
					noHeader
					paginationPerPage={25}
					striped
				/>
			</div>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {})(OrganizationsListTable);
