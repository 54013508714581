import { getAdminUsersList, updateAdminUser } from '../../api';
import React, { useState, useEffect } from 'react';

import './User.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';

const approvalStatusID = {
	PENDING_APPROVAL: 0,
	APPROVED: 1,
	BLOCKED: 2,
};

const approvalStatusText = {
	[approvalStatusID.PENDING_APPROVAL]: 'Pending Approval',
	[approvalStatusID.APPROVED]: 'Approved',
	[approvalStatusID.BLOCKED]: 'Blocked',
};

const StatusActionButton = {
	[approvalStatusID.PENDING_APPROVAL]: ({ userId, fetchData }) => (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<button
				className="bg-blue-400 px-0.5 action-btn primary"
				onClick={async () => {
					await updateAdminUser({
						body: { user: { uuid: userId, update: { admin_approval_status: approvalStatusID.APPROVED } } },
					});

					fetchData();
				}}
			>
				Approve
			</button>
			<button
				className="bg-red-500 px-0.5 action-btn danger"
				onClick={async () => {
					await updateAdminUser({
						body: { user: { uuid: userId, update: { admin_approval_status: approvalStatusID.BLOCKED } } },
					});
					fetchData();
				}}
			>
				Deny
			</button>
		</div>
	),
	[approvalStatusID.APPROVED]: ({ userId, fetchData }) => (
		<button
			className="bg-red-400 px-0.5 action-btn danger"
			onClick={async () => {
				await updateAdminUser({
					body: { user: { uuid: userId, update: { admin_approval_status: approvalStatusID.BLOCKED } } },
				});
				fetchData();
			}}
		>
			Block
		</button>
	),
	[approvalStatusID.BLOCKED]: ({ userId, fetchData }) => (
		<button
			className="bg-blue-400 px-0.5 action-btn primary"
			onClick={async () => {
				await updateAdminUser({
					body: { user: { uuid: userId, update: { admin_approval_status: approvalStatusID.APPROVED } } },
				});

				fetchData();
			}}
		>
			Allow
		</button>
	),
};

const CustomersList = ({ user }) => {
	const [usersList, setUsersList] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchData = async () => {
		try {
			const { data } = await getAdminUsersList();

			setUsersList(data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		console.log(usersList);
	}, [usersList]);

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="flex flex-wrap">
				<h1>Admin Users List</h1>

				<DataTable
					keyField="uuid"
					columns={[
						{
							name: 'Name',
							sortable: true,
							cell: (row) => `${row.first_name} ${row.last_name}`,
						},
						{ name: 'Status', sortable: true, cell: (row) => approvalStatusText[row.admin_approval_status] },
						{
							name: 'Actions',
							sortable: false,
							cell: (row) => {
								if (row.uuid === user.info.uuid) {
									return null;
								}

								const Content = StatusActionButton[row.admin_approval_status];

								return <Content userId={row.uuid} fetchData={fetchData} />;
							},
						},
					]}
					data={usersList || []}
					// customStyles={dataTableStyles(getCurrentLanguage())}
					pagination
					noHeader
					paginationPerPage={25}
					striped
					responsive
				/>
			</div>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {})(CustomersList);
