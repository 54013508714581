import { deleteReq, get, post, put, putFile } from './fetch';
import {
	ADMIN_USERS,
	SIGNIN,
	SIGNUP,
	CREATE_ORGANIZATION,
	ALL_ORGANIZATION,
	ADD_PATIENT,
	ALL_HEALTH__PROVIDERS,
	HEALTH__PROVIDERS_BY_ORGANIZATION_UUID,
	GET_MINDFULNESS_SESSION_PRESIGNED_URL,
	SAVE_MINDFULNESS_SESSION,
	ALL_PATIENTS,
	ALL_WORKOUT_SESSIONS,
	ALL_MINDFULNESS_SESSIONS,
	GENERATE_SESSION_FILE_SIGNED_URL,
	DELETE_MINDFULNESS_SESSION,
	GET_SINGLE_SESSION,
	UPDATE_MIDFULNESS_SESSION,
	GET_SESSION_TRAINERS,
	GET_EDUCATIONAL_DEGREES,
	INVITE_HEALTH_PROVIDER,
} from './endpoints';

export const signupAdmin = ({ firstName, lastName, emailAddress, password }) => {
	const body = { firstName, lastName, emailAddress, password };

	return post({ url: SIGNUP, body, contentType: 'application/json' });
};

export const signinAdmin = ({ emailAddress, password }) => {
	const body = { emailAddress, password };

	return post({ url: SIGNIN, body, contentType: 'application/json' });
};

export const getAdminUsersList = () => {
	return get({ url: ADMIN_USERS, shouldAuthenticate: true });
};

export const updateAdminUser = ({ body }) => {
	return put({ url: ADMIN_USERS, shouldAuthenticate: true, body });
};

export const createOrganization = ({ body }) => {
	return post({ url: CREATE_ORGANIZATION, shouldAuthenticate: true, body, contentType: 'application/json' });
};

export const getAllOrganizations = () => {
	return get({ url: ALL_ORGANIZATION, shouldAuthenticate: true });
};

export const addPatient = ({ body }) => {
	return post({ url: ADD_PATIENT, shouldAuthenticate: true, body, contentType: 'application/json' });
};

export const getPatientList = () => {
	return get({ url: ALL_PATIENTS, shouldAuthenticate: true });
};

export const getAllHealthProviders = () => {
	return get({ url: ALL_HEALTH__PROVIDERS, shouldAuthenticate: true });
};

export const getAllHealthProviderByUuid = (organizationUuid) => {
	return get({ url: `${HEALTH__PROVIDERS_BY_ORGANIZATION_UUID}?organizationUuid=${organizationUuid}`, shouldAuthenticate: true });
};

export const getMindfulnessSessionFilePreSignedURL = ({ filename }) => {
	return get({ url: `${GET_MINDFULNESS_SESSION_PRESIGNED_URL}?filename=${filename}`, shouldAuthenticate: true });
};

export const uploadFileToURL = ({ url, file }) => {
	return putFile({ url, file });
};

export const saveMindfulnessSession = (body) => {
	return post({ url: SAVE_MINDFULNESS_SESSION, shouldAuthenticate: true, body });
};

export const getAllWorkoutSessions = () => {
	return get({
		url: `${ALL_WORKOUT_SESSIONS}?type=video&size=1000`,
		shouldAuthenticate: true,
	});
};

export const getAllMindfulnessSessions = () => {
	return get({
		url: `${ALL_MINDFULNESS_SESSIONS}?type=audio`,
		shouldAuthenticate: true,
	});
};

export const deleteMindfulnessSession = ({ uuid }) => {
	return deleteReq({ url: `${DELETE_MINDFULNESS_SESSION}/?uuid=${uuid}`, shouldAuthenticate: true });
};

export const getSessionFileSignedURL = ({ filename, filetype }) => {
	return get({
		url: `${GENERATE_SESSION_FILE_SIGNED_URL}?filename=${filename}&filetype=${filetype}`,
		shouldAuthenticate: true,
	});
};

export const getSession = ({ uuid }) => {
	return get({ url: `${GET_SINGLE_SESSION}?uuid=${uuid}`, shouldAuthenticate: true });
};

export const updateSession = ({ body }) => {
	return put({ url: UPDATE_MIDFULNESS_SESSION, shouldAuthenticate: true, body });
};

export const getTrainers = () => {
	return get({ url: `${GET_SESSION_TRAINERS}`, shouldAuthenticate: true });
};

export const addTrainer = ({ body }) => {
	return post({
		url: `${GET_SESSION_TRAINERS}`,
		shouldAuthenticate: true,
		body,
		contentType: 'application/json',
	});
};

export const getAllEducationalDegrees = () => {
	return get({ url: GET_EDUCATIONAL_DEGREES });
};

export const getAllUSAStates = () => {
	const url = '/api/v0.1/countries/states';
	const body = { country: 'United States' };

	return post({ baseURL: 'https://countriesnow.space', url, body, contentType: 'application/json' });
};

export const signupHealthProvider = ({ body }) => {
	return post({ url: INVITE_HEALTH_PROVIDER, body, shouldAuthenticate: true, contentType: 'application/json' });
};
