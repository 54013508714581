import { getPatientList } from '../../api';
import React, { useState, useEffect } from 'react';

import './User.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';

const PatientList = ({ user }) => {
	const [patientList, setPatientList] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchData = async () => {
		try {
			const { data } = await getPatientList();
			setPatientList(data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="flex flex-wrap">
				<h1>Patient List</h1>

				<DataTable
					keyField="uuid"
					columns={[
						{
							name: 'Name',
							sortable: true,
							cell: (row) => `${row.firstName} ${row.middleInitial} ${row.lastName}`,
						},
						{ name: 'Email', sortable: true, cell: (row) => row.emailAddress },
						{ name: 'Status', sortable: true, cell: (row) => (row.isConfirmed ? 'Active' : 'In Active') },
					]}
					data={patientList || []}
					pagination
					noHeader
					paginationPerPage={25}
					striped
					responsive
				/>
			</div>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {})(PatientList);
