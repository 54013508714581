import { SIGNIN } from '../types';

const INITIAL_AUTH_STATE = {
	token: null,
	info: null,
};

const user = (state = INITIAL_AUTH_STATE, action = { type: '', payload: {} }) => {
	const { type, payload } = action;

	switch (type) {
		case SIGNIN:
			const { token, userInfo } = payload;

			return { ...state, info: userInfo, token };

		default:
			return state;
	}
};

export default user;
