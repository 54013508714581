import { getAllHealthProviders } from '../../api';
import React, { useState, useEffect } from 'react';

import './User.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';

const healthProviderTypes = {
	DOCTOR: 3,
	PHYSICIAN_ASSISTANT: 4,
	NURSE: 5,
	OTHER_HEALTH_PROVIDER: 6,
};

const healthProviderTypeText = {
	[healthProviderTypes.DOCTOR]: 'Doctor',
	[healthProviderTypes.PHYSICIAN_ASSISTANT]: 'Physician Assistant',
	[healthProviderTypes.NURSE]: 'Nurse',
	[healthProviderTypes.OTHER_HEALTH_PROVIDER]: 'Other Health Provider',
};

const ProviderList = ({ user }) => {
	const [providerList, setProviderList] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchData = async () => {
		try {
			const { data } = await getAllHealthProviders();
			setProviderList(data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<div className="flex flex-wrap">
				<h1>Provider List</h1>

				<DataTable
					keyField="uuid"
					columns={[
						{
							name: 'Name',
							sortable: true,
							cell: (row) => `${row.firstName} ${row.middleInitial} ${row.lastName}`,
						},
						{ name: 'Email', sortable: true, cell: (row) => row.emailAddress },
						{ name: 'Type', sortable: true, cell: (row) =>  healthProviderTypeText[row.roleId]},
					]}
					data={providerList || []}
					pagination
					noHeader
					paginationPerPage={25}
					striped
					responsive
				/>
			</div>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {})(ProviderList);
