import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import './styles.css';

// components

import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import HeaderStats from 'components/Headers/HeaderStats.js';
import MediaPlayer from '../views/admin/MediaPlayer';
import FooterAdmin from 'components/Footers/FooterAdmin.js';

// views

import Dashboard from 'views/admin/Dashboard.js';
import Maps from 'views/admin/Maps.js';
import Settings from 'views/admin/Settings.js';
import Tables from 'views/admin/Tables.js';
import Users from '../views/admin/Users';
import OrganizationsList from '../views/admin/OrganizationsList';
import CreateOrganization from '../views/admin/CreateOrganization';
import AddPatient from '../views/admin/AddPatient';
import addProvider from '../views/admin/AddProvider';
import UploadMindfulnessSession from '../views/admin/UploadMindfulnessSession';
import PatientList from '../views/admin/PatientList';
import ProviderList from '../views/admin/ProviderList';
import { getCookie } from 'utils';
import { saveUserAuth } from 'store/actions/user';
import { connect } from 'react-redux';
import WorkoutSessionsList from 'views/admin/WorkoutSessionsList';
import MindfulnessSessionsList from 'views/admin/MindfulnessSessionsList';

const Admin = ({ saveUserAuthAction }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState(null);

	useEffect(() => {
		const cookieToken = getCookie({ key: 'access_token' });
		const user = getCookie({ key: 'u' });

		if (cookieToken && cookieToken !== null) {
			saveUserAuthAction({ token: cookieToken, user });
			setToken(cookieToken);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	});

	return (
		<>
			<Sidebar />
			<div className="relative md:ml-64 bg-gray-200 height-100vh">
				<AdminNavbar />
				{/* Header */}
				<HeaderStats />
				<div className="px-4 md:px-10 mx-auto w-full -mt-32">
					{!isLoading && token && (
						<Switch>
							<Route path="/admin/dashboard" exact component={Dashboard} />
							<Route path="/admin/maps" exact component={Maps} />
							<Route path="/admin/settings" exact component={Settings} />
							<Route path="/admin/tables" exact component={Tables} />
							<Route path="/admin/users" exact component={Users} />
							<Route path="/admin/organization/create" exact component={CreateOrganization} />
							<Route path="/admin/organizations" exact component={OrganizationsList} />
							<Route path="/admin/patient/create" exact component={AddPatient} />
							<Route path="/admin/uploadMindfulnessSession" exact component={UploadMindfulnessSession} />
							<Route path="/admin/media" exact component={MediaPlayer} />
							<Route path="/admin/patient/list" exact component={PatientList} />
							<Route path="/admin/provider/list" exact component={ProviderList} />
							<Route path="/admin/workoutSession/list" exact component={WorkoutSessionsList} />
							<Route path="/admin/mindfulnessSession/list" exact component={MindfulnessSessionsList} />
							<Route path="/admin/provider/create" exact component={addProvider} />

							<Redirect from="/admin" to="/admin/dashboard" />
						</Switch>
					)}

					{!isLoading && !token && <Redirect from="/auth" to="/auth" />}
					{/* <FooterAdmin /> */}
				</div>
			</div>
		</>
	);
};

const mapActionToProps = {
	saveUserAuthAction: saveUserAuth,
};

export default connect(null, mapActionToProps)(Admin);
