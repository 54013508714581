import { deleteMindfulnessSession, getAllMindfulnessSessions } from '../../api';
import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import './User.css';

import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';

const MindfulnessSessionsList = ({ user, history }) => {
	const [mindfulnessSessions, setMindfulnessSessions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [updateSesssionOfUUID, setUpdateSessionOfUUID] = useState(null);

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const { data } = await getAllMindfulnessSessions();

			setMindfulnessSessions(data);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const deleteSession = async ({ uuid }) => {
		setIsLoading(true);

		try {
			await deleteMindfulnessSession({ uuid });

			fetchData();
		} catch (error) {
			alert(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	if (updateSesssionOfUUID) {
		return <Redirect to={`/admin/uploadMindfulnessSession?uuid=${updateSesssionOfUUID}`} />;
	}

	return (
		<>
			<div className="flex flex-wrap">
				<h1>Organizations List</h1>

				<DataTable
					keyField="userId"
					responsive
					columns={[
						{
							name: 'Name',
							sortable: true,
							selector: 'name',
							grow: 2,
						},
						{
							name: 'Session Type',
							sortable: true,
							selector: 'sessionType',
							grow: 1,
						},
						{
							name: 'Category',
							sortable: true,
							selector: 'category',
							grow: 1,
						},
						{
							name: 'Order',
							sortable: true,
							selector: 'order',
							grow: 1,
						},
						{
							name: 'Duration',
							sortable: true,
							selector: 'duration',
							grow: 1,
						},
						{
							name: 'Trainer',
							sortable: true,
							selector: 'trainerName',
							grow: 2,
						},
						{
							name: 'Description',
							sortable: true,
							selector: 'description',
							grow: 3,
						},
						{
							name: 'Action',
							sortable: false,
							grow: 3,
							cell: (row, index) => {
								return (
									<div className="row">
										<button
											className="bg-blue-900 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											disabled={isLoading}
											onClick={() => history.push(`/admin/uploadMindfulnessSession?uuid=${row.uuid}`)}
										>
											Update
										</button>
										<button
											className="bg-blue-900 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
											disabled={isLoading}
											onClick={() => deleteSession({ uuid: row.uuid })}
										>
											Delete
										</button>
									</div>
								);
							},
						},
					]}
					data={mindfulnessSessions || []}
					// customStyles={dataTableStyles(getCurrentLanguage())}
					pagination
					noHeader
					paginationPerPage={25}
					striped
				/>
			</div>
		</>
	);
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {})(MindfulnessSessionsList);
