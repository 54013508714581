import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import { createOrganization } from '../../api';

const CreateOrganization = ({}) => {
	const { register, handleSubmit, errors, reset } = useForm();
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			await createOrganization({ body: data });
			setIsLoading(false);
			reset();
		} catch (error) {
			setIsLoading(false);
			alert(error.message || 'Something went wrong. Try again later!');
		}
	};

	return (
		<>
			<div className="container mx-auto px-4 h-full">
				<div className="flex content-center items-center justify-center h-full">
					<div className="px-4">
						<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
							<div className="flex-auto px-4 lg:px-10 py-10 pt-0">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="row">
										<div className="px-4 flex-1 column">
											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Organization Name
												</label>
												<input
													type="text"
													name="name"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Organization Name"
													ref={register({
														required: { value: true, message: 'The name of the organization is required.' },
													})}
												/>
												<ErrorMessage errors={errors} name="name" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Address Line 1
												</label>
												<input
													type="text"
													name="address1"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Unit, Street"
													ref={register({
														required: { value: true, message: 'Address Line 1 is required.' },
													})}
												/>
												<ErrorMessage errors={errors} name="address1" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Address Line 2
												</label>
												<input
													type="text"
													name="address2"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Block, Area"
													ref={register({
														required: false,
													})}
												/>
												<ErrorMessage errors={errors} name="address2" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													City
												</label>
												<input
													type="text"
													name="city"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="City"
													ref={register({
														required: { value: true, message: 'City is required.' },
													})}
												/>
												<ErrorMessage errors={errors} name="city" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													State
												</label>
												<input
													type="text"
													name="state"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="State"
													ref={register({
														required: { value: true, message: 'State is required.' },
													})}
												/>
												<ErrorMessage errors={errors} name="state" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Zip code
												</label>
												<input
													type="text"
													name="zip"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Zip"
													ref={register({
														required: { value: true, message: 'Zip code is required.' },
													})}
												/>
												<ErrorMessage errors={errors} name="zip" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Tax ID
												</label>
												<input
													type="text"
													name="taxID"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Tax ID"
													ref={register({
														required: { value: true, message: 'Tax ID is required.' },
													})}
												/>
												<ErrorMessage errors={errors} name="taxID" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Domain name
												</label>
												<input
													type="text"
													name="domain"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Domain name"
													ref={register({
														required: { value: true, message: 'Domain name is required.' },
													})}
												/>
												<ErrorMessage errors={errors} name="domain" as={FormFieldErrorMessage} />
											</div>
										</div>

										<div className="px-4 flex-1 column">
											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Contact Person's First Name
												</label>
												<input
													type="text"
													name="contactFirstName"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="First Name"
													ref={register({
														required: { value: true, message: "Contact person's first name is required." },
													})}
												/>
												<ErrorMessage errors={errors} name="contactFirstName" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Contact Person's Last Name
												</label>
												<input
													type="text"
													name="contactLastName"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Last Name"
													ref={register({
														required: { value: true, message: "Contact person's last name is required." },
													})}
												/>
												<ErrorMessage errors={errors} name="contactLastName" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Contact Person's Role
												</label>
												<input
													type="text"
													name="contactRole"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Role"
													ref={register({
														required: { value: true, message: "Contact person's role is required." },
													})}
												/>
												<ErrorMessage errors={errors} name="contactRole" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Contact Person's Email Address
												</label>
												<input
													type="email"
													name="contactEmail"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Email Address"
													ref={register({
														required: { value: true, message: "Contact person's email address is required." },
													})}
												/>
												<ErrorMessage errors={errors} name="contactEmail" as={FormFieldErrorMessage} />
											</div>

											<div className="relative w-full mb-3">
												<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Contact Person's Phone Number
												</label>
												<input
													type="text"
													name="contactPhoneNumber"
													className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
													placeholder="Phone Number"
													ref={register({
														required: { value: true, message: "Contact person's phone number is required." },
													})}
												/>
												<ErrorMessage errors={errors} name="contactPhoneNumber" as={FormFieldErrorMessage} />
											</div>
										</div>
									</div>

									<div className="text-center mt-6">
										<button
											className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
											type="submit"
											disabled={isLoading}
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateOrganization;
