import React from 'react';

const AddPatientGeneralInfo = ({
	register,
	errors,
	healthProviderList,
	organizationList,
	organizationChanged,
	ErrorMessage,
	FormFieldErrorMessage,
	deviceData,
	setDeviceData,
}) => {
	return (
		<div className="flex flex-col">
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								First Name
							</label>
							<input
								type="name"
								name="firstName"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="John"
								ref={register({ required: { value: true, message: 'First name is required.' } })}
							/>
							<ErrorMessage errors={errors} name="firstName" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Middle Name
							</label>
							<input
								type="name"
								name="middleInitial"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="John"
								ref={register({ required: { value: true, message: 'Middle Initial is required.' } })}
							/>
							<ErrorMessage errors={errors} name="middleInitial" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Last Name
							</label>
							<input
								type="name"
								name="lastName"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="Doe"
								ref={register({ required: { value: true, message: 'Last name is required' } })}
							/>
							<ErrorMessage errors={errors} name="lastName" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								DOB
							</label>
							<input
								type="date"
								name="dateOfBirth"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline width-125 ease-linear transition-all duration-150"
								placeholder="DOB"
								ref={register({ required: { value: true, message: 'Date of Birth is required' } })}
							/>
							<ErrorMessage errors={errors} name="dateOfBirth" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Height
							</label>
							<input
								type="number"
								name="heightFeet"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline width-45 ease-linear transition-all duration-150"
								placeholder="Feet"
								ref={register({ required: { value: true, message: 'Height is required' } })}
							/>
							<input
								type="number"
								name="heightInches"
								className="px-3 py-3 ml-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline width-45 ease-linear transition-all duration-150"
								placeholder="Inches"
								ref={register({ required: { value: true, message: 'Height is required' } })}
							/>
							<ErrorMessage errors={errors} name="height" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Weight
							</label>
							<input
								type="number"
								name="weight"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="Lbs"
								ref={register({ required: { value: true, message: 'Weight is required' } })}
							/>
							<ErrorMessage errors={errors} name="weight" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Race/Ethnicity
							</label>
							<select
								name="race"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="White">
									White
								</option>
								<option key="2" value="Black or African American">
									Black or African American
								</option>
								<option key="3" value="Hispanic/Latino">
									Hispanic/Latino
								</option>
								<option key="4" value="American Indian or Alaska Native">
									American Indian or Alaska Native
								</option>
								<option key="5" value="Asian">
									Asian
								</option>
								<option key="6" value="Native Hawaiian or Other Pacific Islander">
									Native Hawaiian or Other Pacific Islander
								</option>
								<option key="7" value="Other">
									Other
								</option>
							</select>
							<ErrorMessage errors={errors} name="race" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item width-50 ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Primary Language
							</label>
							<select
								name="primaryLanguage"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="English">
									English
								</option>
								<option key="2" value="Spanish">
									Spanish
								</option>
							</select>
							<ErrorMessage errors={errors} name="primaryLanguage" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Marital Status
							</label>
							<select
								name="maritalStatus"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="Widowed">
									Widowed
								</option>
								<option key="2" value="Divorced or Separated">
									Divorced or Separated
								</option>
								<option key="3" value="Married or living with partner">
									Married or living with partner
								</option>
							</select>
							<ErrorMessage errors={errors} name="maritalStatus" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item width-50 ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
							Highest level of education
							</label>
							<select
								name="education"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="<=12th grade">
									{'<=12th grade'}
								</option>
								<option key="2" value="High school graduate/GED">
									High school graduate/GED
								</option>
								<option key="3" value="Some college or AA degree">
									Some college or AA degree
								</option>
								<option key="4" value="College graduate or above">
									College graduate or above
								</option>
							</select>
							<ErrorMessage errors={errors} name="education" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Phone Number
							</label>
							<input
								type="phone"
								name="phoneNumber"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="phoneNumber"
								ref={register({
									required: { value: true, message: 'Phone Number is required.' },
									minLength: { value: 8, message: 'Phone number must be 8 characters long.' },
								})}
							/>
							<ErrorMessage errors={errors} name="phoneNumber" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Email
							</label>
							<input
								type="email"
								name="emailAddress"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="example@ucardia.com"
								ref={register({ required: { value: true, message: 'Email address is required.' } })}
							/>
							<ErrorMessage errors={errors} name="emailAddress" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="relative w-full mb-3">
					<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
						Shipping Address
					</label>
					<input
						type="name"
						name="addressPrimary"
						className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
						placeholder="Address1"
						ref={register({ required: { value: true, message: 'Address is required.' } })}
					/>
					<ErrorMessage errors={errors} name="addressPrimary" as={FormFieldErrorMessage} />
				</div>
			</div>
			<div className="flex-item">
				<div className="relative w-full mb-3">
					{/* <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Email
												</label> */}
					<input
						type="name"
						name="addressSecondary"
						className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
						placeholder="Address2"
						ref={register({ required: { value: true, message: 'Address is required.' } })}
					/>
					<ErrorMessage errors={errors} name="addressSecondary" as={FormFieldErrorMessage} />
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							{/* <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Email
												</label> */}
							<input
								type="name"
								name="city"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="City"
								ref={register({ required: { value: true, message: 'City is required.' } })}
							/>
							<ErrorMessage errors={errors} name="city" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item width-25">
						<div className="relative w-full mb-3 pl-3">
							{/* <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Email
												</label> */}
							<input
								type="name"
								name="state"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="State"
								ref={register({ required: { value: true, message: 'State is required.' } })}
							/>
							<ErrorMessage errors={errors} name="state" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item width-25">
						<div className="relative w-full mb-3 pl-3">
							{/* <label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
													Email
												</label> */}
							<input
								type="name"
								name="zipCode"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="Zip"
								ref={register({ required: { value: true, message: 'Zip Code is required.' } })}
							/>
							<ErrorMessage errors={errors} name="zipCode" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item w-full">
				<div className="relative w-full mb-3">
					<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
						Organization
					</label>
					<select
						name="organizationUuid"
						ref={register}
						onChange={organizationChanged}
						className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
					>
						{organizationList.map((value) => (
							<option key={value.uuid} value={value.uuid}>
								{value.name}
							</option>
						))}
					</select>
					<ErrorMessage errors={errors} name="healthProviderId" as={FormFieldErrorMessage} />
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Health Provider
							</label>
							<select
								name="healthProviderUuid"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								{healthProviderList.map((value) => (
									<option key={value.uuid} value={value.uuid}>
										{value.firstName} {value.middleInitial} {value.lastName}
									</option>
								))}
							</select>
							<ErrorMessage errors={errors} name="healthProviderUuid" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Medical Record Number (MRN)
							</label>
							<input
								type="name"
								name="patientMedicalRecordNumber"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="Medical Record Number"
								ref={register({
									required: { value: true, message: 'Patient Medical Record Number is required.' },
								})}
							/>
							<ErrorMessage errors={errors} name="patientMedicalRecordNumber" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-60">Does Patient Have wifi at home?</div>
					<div className="flex-item width-40">
						<div className="flex flex-row switch-button text-center color-white">
							<div
								className={'flex-item width-50 ' + (deviceData.isWifiAvailable ? 'bg-selected-color radius-left' : '')}
								onClick={() => setDeviceData({ ...deviceData, isWifiAvailable: true })}
							>
								Yes
							</div>
							<span>|</span>
							<div
								className={'flex-item width-50 ' + (deviceData.isWifiAvailable ? '' : 'bg-selected-color radius-right')}
								onClick={() => setDeviceData({ ...deviceData, isWifiAvailable: false })}
							>
								No
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item pt-2">
				<div className="flex flex-row">
					<div className="flex-item width-60">Which Phone/OS does patient have?</div>
					<div className="flex-item width-40">
						<div className="flex flex-row switch-button text-center color-white">
							<div
								className={
									'flex-item width-33 ' +
									(deviceData.availableDevice == 'iPhone' ? 'bg-selected-color radius-left' : '')
								}
								onClick={() => setDeviceData({ ...deviceData, availableDevice: 'iPhone' })}
							>
								iPhone
							</div>
							<span>|</span>
							<div
								className={'flex-item width-33 ' + (deviceData.availableDevice == 'android' ? 'bg-selected-color' : '')}
								onClick={() => setDeviceData({ ...deviceData, availableDevice: 'android' })}
							>
								Android
							</div>
							<span>|</span>
							<div
								className={
									'flex-item width-33 ' +
									(deviceData.availableDevice == 'other' ? 'bg-selected-color radius-right' : '')
								}
								onClick={() => setDeviceData({ ...deviceData, availableDevice: 'other' })}
							>
								Other
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex-item pt-2">
				<div className="flex flex-row">
					<div className="flex-item width-60">Provide patient with smart scale?</div>
					<div className="flex-item width-40">
						<div className="flex flex-row switch-button text-center color-white">
							<div
								className={
									'flex-item width-50 ' + (deviceData.provideSmartScale ? 'bg-selected-color radius-left' : '')
								}
								onClick={() => setDeviceData({ ...deviceData, provideSmartScale: true })}
							>
								Yes
							</div>
							<span>|</span>
							<div
								className={
									'flex-item width-50 ' + (deviceData.provideSmartScale ? '' : 'bg-selected-color radius-right')
								}
								onClick={() => setDeviceData({ ...deviceData, provideSmartScale: false })}
							>
								No
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="flex-item pt-2">
				<div className="flex flex-row">
					<div className="flex-item width-60">Provide Patient with at home ECG?</div>
					<div className="flex-item width-40">
						<div className="flex flex-row switch-button text-center color-white">
							<div
								className={'flex-item width-50 ' + (deviceData.provideECG ? 'bg-selected-color radius-left' : '')}
								onClick={() => setDeviceData({ ...deviceData, provideECG: true })}
							>
								Yes
							</div>
							<span>|</span>
							<div
								className={'flex-item width-50 ' + (deviceData.provideECG ? '' : 'bg-selected-color radius-right')}
								onClick={() => setDeviceData({ ...deviceData, provideECG: false })}
							>
								No
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item pt-2">
				<div className="flex flex-row">
					<div className="flex-item width-60 text-gray-700">Blood pressure cuff size</div>
					<div className="flex-item width-40">
					<div className="relative w-full mb-3">
							<select
								name="bloodPressureCuffSize"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="Standard: 8.6 to 11.8 inches (21.8 to 29.9 cm)">
									Standard: 8.6 to 11.8 inches (21.8 to 29.9 cm)
								</option>
								<option key="2" value="Large: 11.8 to 16.5 inches (29.9 to 41.9 cm)">
									Large: 11.8 to 16.5 inches (29.9 to 41.9 cm)
								</option>
								<option key="3" value="Extra Large: 16.5 to 18.9 inches (41.9 to 48 cm)">
									Extra Large: 16.5 to 18.9 inches (41.9 to 48 cm)
								</option>
							</select>
							<ErrorMessage errors={errors} name="bloodPressureCuffSize" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddPatientGeneralInfo;
