export const SIGNUP = '/user/admin';
export const SIGNIN = '/session/admin';
export const ADMIN_USERS = '/user/admin';
export const CREATE_ORGANIZATION = '/organization';
export const ALL_ORGANIZATION = '/organization';
export const ADD_PATIENT = '/user/patient';
export const ALL_HEALTH__PROVIDERS = '/user/healthProvider';
export const HEALTH__PROVIDERS_BY_ORGANIZATION_UUID = '/user/healthProviderByUuid';
export const GET_MINDFULNESS_SESSION_PRESIGNED_URL = '/mindfulnessSession/generateSignedURL';
export const SAVE_MINDFULNESS_SESSION = '/mindfulnessSession';
export const ALL_PATIENTS = '/user/patient/list';
export const ALL_WORKOUT_SESSIONS = '/mindfulnessSession';
export const ALL_MINDFULNESS_SESSIONS = '/mindfulnessSession';
export const DELETE_MINDFULNESS_SESSION = 'mindfulnessSession';
export const GENERATE_SESSION_FILE_SIGNED_URL = '/mindfulnessSession/generateSignedURL';
export const GET_SINGLE_SESSION = '/mindfulnessSession/single';
export const UPDATE_MIDFULNESS_SESSION = '/mindfulnessSession';
export const GET_SESSION_TRAINERS = '/sessionTrainer/list';
export const GET_EDUCATIONAL_DEGREES = '/educationalDegree';
export const INVITE_HEALTH_PROVIDER = '/user/admin/healthProvider';
