import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import './AddProvider.css';

import FormFieldErrorMessage from '../../components/FormFieldErrorMessage';
import { getAllEducationalDegrees, getAllOrganizations, getAllUSAStates, signupHealthProvider } from '../../api';

const languages = {
	en: { name: 'English', isSelected: false },
	sp: { name: 'Spanish', isSelected: false },
};

const UserInfoSection = ({}) => {
	const [isSelectStateVisible, setIsSelectStateVisible] = useState(false);
	const [userInfo, setUserInfo] = useState({});
	const [isSelectLanguageVisible, setIsSelectLanguageVisible] = useState(false);
	const [educationalDegrees, setEducationalDegrees] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [selectedDegree, setSelectedDegree] = useState(null);
	const [selectedOrg, setSelectedOrg] = useState(null);
	const [selectedLanguage, setSelectedLanguage] = useState({ ...languages });
	const [selectedStates, setSelectedStates] = useState({});
	const [degreeError, setDegreeError] = useState(null);
	const [orgError, setOrgError] = useState(null);
	const [languageError, setLanguageError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const { register, handleSubmit, errors, reset } = useForm();

	const stateDropDown = useRef(null);
	const languageDropDown = useRef(null);
	const loadOrganizations = async () => {
		try {
			const response = await getAllOrganizations();

			setOrganizations(response.data);
		} catch (e) {
			console.log(e);
		}
	};

	const loadEducationalDegrees = async () => {
		try {
			const response = await getAllEducationalDegrees();

			setEducationalDegrees(response.data);
		} catch (e) {
			console.log(e);
		}
	};

	const loadStates = async () => {
		try {
			const response = await getAllUSAStates();

			const {
				data: { states },
			} = response;

			const statesInfo = states.reduce((acc, state) => ({ ...acc, [state.name]: { ...state, isSelected: false } }), {});

			setSelectedStates(statesInfo);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		loadEducationalDegrees();
		loadOrganizations();
		loadStates();
		document.addEventListener('mousedown', handleClickOutside);
	}, []);

	const onSubmit = async (data) => {
		if (!selectedDegree) {
			setDegreeError('Please select you degree.');
			return;
		}

		if (!selectedOrg) {
			setOrgError('Please select organisation.');
		}

		if (!Object.keys(selectedLanguage).some((item) => selectedLanguage[item].isSelected)) {
			setLanguageError('Please select at least one language.');
			return;
		}

		setIsLoading(true);

		const { id: educationalDegreeId, fk_role_id } = educationalDegrees.reduce((acc, item) => {
			if (item.name === selectedDegree) {
				return item;
			} else {
				return acc;
			}
		}, "");
		const { uuid: selectedOrgId } = organizations.reduce((acc, item) => {
			if (item.uuid === selectedOrg) {
				return item;
			} else {
				return acc;
			}
		}, "");
		const licensedForStates = Object.keys(selectedStates).reduce((acc, key) => {
			const { name, isSelected } = selectedStates[key];

			if (isSelected) {
				return [...acc, name];
			} else {
				return acc;
			}
		}, []);
		const languagesInfo = Object.keys(selectedLanguage).reduce((acc, key) => {
			const { name, isSelected } = selectedLanguage[key];

			if (isSelected) {
				return [...acc, name];
			} else {
				return acc;
			}
		}, []);
		
		const newHealthProvider = {
			...userInfo,
			...data,
			educationalDegreeId,
			organizationId: selectedOrgId,
			roleId: fk_role_id,
			stateLicense: licensedForStates,
			languagesInfo,
		};

		setUserInfo(newHealthProvider);

		try {
			const response = await signupHealthProvider({ body: newHealthProvider });

			alert(response.message);
			reset();
		} catch (error) {
			alert(error.message || 'Something went wrong. Try again later!');
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickOutside = (event) => {
		if (!stateDropDown.current.contains(event.target) && !stateDropDown.current.contains(event.target.parentNode)) {
			setIsSelectStateVisible(false);
		}
		if (!languageDropDown.current.contains(event.target) && !languageDropDown.current.contains(event.target.parentNode)) {
			setIsSelectLanguageVisible(false);
		}
	};

	return (
		<div className="p-relative">
			<div
				className="p-absolute top-0 bottom-0 left-0 right-0"
				onClick={() => {
					setIsSelectStateVisible(false);
					setIsSelectLanguageVisible(false);
				}}
			/>
			<div className="container mx-auto px-4 h-full">
				<div className="flex content-center items-center justify-center h-full">
					<div className="px-4">
						<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
							<div className="flex-auto px-4 lg:px-10 py-10 pt-6">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className={classnames('flex flex-1 width-cover-all column signup-form')}>
										<div className="flex flex-1 width-cover-all column">
											<div className="row flex width-cover-all" id="form-row">
												<div className="form-col-1">
													<label className="form-input-label">First Name</label>
													<input
														type="name"
														name="firstName"
														className="width-cover-all form-input"
														placeholder=""
														ref={register({ required: { value: true, message: 'First name is required.' } })}
													/>
												</div>

												<div className="form-col-2 col">
													<label className="form-input-label">MI</label>
													<input
														type="text"
														name="middleInitial"
														className="form-input"
														id="mi-input"
														placeholder=""
														ref={register({ required: { value: true, message: 'MI is required.' } })}
													/>
												</div>
											</div>

											<div className="flex width-cover-all">
												<ErrorMessage errors={errors} name="firstName" as={FormFieldErrorMessage} />
												<ErrorMessage errors={errors} name="middleInitial" as={FormFieldErrorMessage} />
											</div>

											<div className="row flex width-cover-all" id="form-row">
												<div className="form-col-1">
													<label className="form-input-label">Last Name</label>
													<input
														type="name"
														name="lastName"
														className="width-cover-all form-input"
														placeholder=""
														ref={register({ required: { value: true, message: 'Last name is required.' } })}
													/>
												</div>

												<div className="form-col-2 flex flex-1 column">
													<label className="form-input-label">Degree</label>

													<div className="drop-down">
														<select
															name="degree"
															className="width-cover-all form-input"
															defaultValue=""
															onChange={({ target: { value } }) => {
																setDegreeError(null);
																setSelectedDegree(value);
															}}
														>
															<option className="d-none options-placeholder">Select</option>

															{educationalDegrees.map((degree) => {
																const { name } = degree;

																return (
																	<option key={name} value={name}>
																		{name}
																	</option>
																);
															})}
														</select>
													</div>
												</div>
											</div>

											<div className="flex width-cover-all">
												<ErrorMessage errors={errors} name="lastName" as={FormFieldErrorMessage} />
												{degreeError && <FormFieldErrorMessage>{degreeError}</FormFieldErrorMessage>}
											</div>

											<div className="row flex width-cover-all" id="form-row">
												<div className="form-col-1">
													<label className="form-input-label">NPI</label>
													<input
														type="text"
														name="npi"
														className="width-cover-all form-input"
														placeholder=""
														ref={register({ required: { value: true, message: 'NPI is required.' } })}
													/>
												</div>

												<div className="form-col-2 flex flex-1 column">
													<label className="form-input-label">State License</label>

													<div
														className="p-relative drop-down width-cover-all form-input bg-white flex ai-center cursor-pointer"
														onClick={(e) => {
															e.stopPropagation();
															setIsSelectStateVisible(true);
														}}
													>
														Select all that apply
														<div
															className={classnames('bg-white scrollable-y p-absolute', {
																visible: isSelectStateVisible,
															})}
															id="language-dropdown-options"
															ref={stateDropDown}
														>
															<div
																className="drop-down-option cursor-pointer"
																onClick={() => {
																	const isCheckedAll = Object.keys(selectedStates).reduce((acc, key) => {
																		return acc && selectedStates[key].isSelected;
																	}, true);

																	setSelectedStates(
																		Object.keys(selectedStates).reduce((acc, key) => {
																			return { ...acc, [key]: { ...selectedStates[key], isSelected: !isCheckedAll } };
																		}, {}),
																	);
																}}
															>
																<input
																	className="select-all"
																	type="checkbox"
																	checked={Object.keys(selectedStates).reduce((acc, key) => {
																		return acc && selectedStates[key].isSelected;
																	}, true)}
																	readOnly
																/>{' '}
																Select All
															</div>

															{Object.keys(selectedStates).map((stateKey) => {
																const { name, isSelected } = selectedStates[stateKey];

																return (
																	<div
																		key={name}
																		className="drop-down-option"
																		onClick={() =>
																			setSelectedStates({
																				...selectedStates,
																				[stateKey]: {
																					...selectedStates[stateKey],
																					isSelected: !selectedStates[stateKey].isSelected,
																				},
																			})
																		}
																	>
																		<input type="checkbox" checked={isSelected} readOnly /> {name}
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</div>

											<div className="flex width-cover-all">
												<ErrorMessage errors={errors} name="npi" as={FormFieldErrorMessage} />
											</div>

											<div className="row flex width-cover-all" id="form-row">
												<div className="form-col-1">
													<label className="form-input-label">Email Address</label>
													<input
														type="email"
														name="emailAddress"
														className="width-cover-all form-input"
														placeholder=""
														ref={register({ required: { value: true, message: 'Email address is required.' } })}
													/>
												</div>

												<div className="form-col-2 flex flex-1 column">
													<label className="form-input-label">Organization</label>

													<div className="drop-down">
														<select
															name="organisation"
															className="width-cover-all form-input"
															defaultValue=""
															onChange={({ target: { value } }) => {
																setOrgError(null);
																setSelectedOrg(value);
															}}
														>
															<option className="d-none options-placeholder">Select</option>

															{organizations.map((org) => {
																const { uuid, name } = org;

																return (
																	<option key={uuid} value={uuid}>
																		{name}
																	</option>
																);
															})}
														</select>
													</div>
												</div>
											</div>

											<div className="flex width-cover-all">
												<ErrorMessage errors={errors} name="emailAddress" as={FormFieldErrorMessage} />
												{orgError && <FormFieldErrorMessage>{orgError}</FormFieldErrorMessage>}
											</div>

											<div className="row flex width-cover-all" id="form-row">
												<div className="form-col-1 flex row">
													<div className="flex column" id="office-phone-containter">
														<label className="form-input-label">Office Phone</label>
														<input
															type="text"
															name="officePhone"
															className="width-cover-all form-input"
															placeholder=""
															ref={register({ required: { value: true, message: 'Office phone is required.' } })}
														/>
													</div>

													<div className="flex flex-1 column" id="fax-container">
														<label className="form-input-label">Fax</label>
														<input
															type="text"
															name="fax"
															className="width-cover-all form-input"
															placeholder=""
															ref={register({ required: { value: true, message: 'Fax is required.' } })}
														/>
													</div>
												</div>

												<div className="form-col-2 flex flex-1 column">
													<label className="form-input-label">Language(s)</label>

													<div
														className="p-relative drop-down width-cover-all form-input bg-white flex ai-center cursor-pointer"
														onClick={(e) => {
															e.stopPropagation();
															setIsSelectLanguageVisible(true);
														}}
													>
														Select all that apply
														<div
															className={classnames('bg-white scrollable-y p-absolute', {
																visible: isSelectLanguageVisible,
															})}
															id="language-dropdown-options"
															ref={languageDropDown}
														>
															<div
																className="drop-down-option cursor-pointer"
																onClick={() => {
																	const isCheckedAll = Object.keys(selectedLanguage).reduce((acc, key) => {
																		return acc && selectedLanguage[key].isSelected;
																	}, true);

																	if (isCheckedAll) {
																		setLanguageError(null);
																	}

																	setSelectedLanguage(
																		Object.keys(selectedLanguage).reduce((acc, key) => {
																			return { ...acc, [key]: { ...selectedLanguage[key], isSelected: !isCheckedAll } };
																		}, {}),
																	);
																}}
															>
																<input
																	classnames="select-all"
																	type="checkbox"
																	checked={Object.keys(selectedLanguage).reduce((acc, key) => {
																		return acc && selectedLanguage[key].isSelected;
																	}, true)}
																	readOnly
																/>{' '}
																Select All
															</div>

															{Object.keys(selectedLanguage).map((languageKey) => {
																const { name, isSelected } = selectedLanguage[languageKey];

																return (
																	<div
																		key={name}
																		className="drop-down-option"
																		onClick={() => {
																			!isSelected && setLanguageError(null);

																			setSelectedLanguage({
																				...selectedLanguage,
																				[languageKey]: {
																					...selectedLanguage[languageKey],
																					isSelected: !selectedLanguage[languageKey].isSelected,
																				},
																			});
																		}}
																	>
																		<input type="checkbox" checked={isSelected} readOnly /> {name}
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</div>

											<div className="flex width-cover-all">
												<ErrorMessage errors={errors} name="officePhone" as={FormFieldErrorMessage} />
												<ErrorMessage errors={errors} name="fax" as={FormFieldErrorMessage} />
												{languageError && <FormFieldErrorMessage>{languageError}</FormFieldErrorMessage>}
											</div>
										</div>

										<div id="proceed-button-container" className="flex jc-flex-end ai-center">
											<button
												className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
												type="submit"
												disabled={
													errors.firstName ||
													errors.lastName ||
													errors.mi ||
													errors.npi ||
													errors.emailAddress ||
													errors.fax ||
													errors.officePhone ||
													degreeError ||
													languageError ||
													isLoading
												}
											>
												Submit
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserInfoSection;
