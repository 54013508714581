import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import queryString from 'query-string';
import {
	addTrainer,
	createOrganization,
	getMindfulnessSessionFilePreSignedURL,
	getSession,
	getSessionFileSignedURL,
	getTrainers,
	saveMindfulnessSession,
	updateSession,
	uploadFileToURL,
} from '../../api';

import './UploadMindfulnessSession.css';
import { UCARDIA_API_BASE_URL } from 'config';
import { SAVE_MINDFULNESS_SESSION } from 'api/endpoints';
import { useHistory } from 'react-router';

const categoryIDDictionary = {
	mindful: 0,
	nature: 1,
	breathe: 2,
};

const SessionTypes = {
	AUDIO: 0,
	VIDEO: 1,
	NON_LIVE_VIDEO: 2,
};

const categoryNameDictionary = {
	[categoryIDDictionary.mindful]: 'mindful',
	[categoryIDDictionary.nature]: 'nature',
	[categoryIDDictionary.breathe]: 'breathe',
};

const UploadSession = ({ location }) => {
	const { register, handleSubmit, errors, reset, setValue } = useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [isAudioSelected, setIsAudioSelected] = useState(null);
	const [sessionType, setSessionType] = useState(SessionTypes.AUDIO);
	const [image, setImage] = useState(null);
	const [sessionFile, setSessionFile] = useState(null);
	const [trainers, setTrainers] = useState([]);
	const [filteredTrainers, setFilteredTrainers] = useState([]);
	const [selectedTrainer, setSelectedTrainer] = useState({
		name: '',
		uuid: '',
	});
	const [trainerToSearch, setTrainerToSearch] = useState('');
	const [isTrainerSelectVisible, setIsTrainerSelectVisible] = useState(false);
	const [sessionToUpdate, setSessionToUpdate] = useState(null);
	const [vimeoVideoUrl, setVimeoVideoUrl] = useState('');
	const [vimeoVideoThumb, setVimeoVideoThumb] = useState('');
	const history = useHistory();

	const loadTrainers = async () => {
		try {
			const { data } = await getTrainers();
			setTrainers(data);
		} catch (error) {}
	};

	const filterTrainers = (event) => {
		const { value } = event.target;

		const trainersAfterFilter = trainers.filter((trainer) => {
			const { firstName: trainerName } = trainer;
			return trainerName.toLowerCase().includes(value.toLowerCase());
		});
		setTrainerToSearch(value);
		setFilteredTrainers(trainersAfterFilter);
	};

	useEffect(() => {
		setFilteredTrainers(trainers);

		if (sessionToUpdate && selectedTrainer.uuid === '') {
			const { trainerUuid } = sessionToUpdate;
			const trainer = trainers.reduce((acc, trainerItem) => {
				const { uuid } = trainerItem;

				if (uuid === trainerUuid) {
					return trainerItem;
				}
				return acc;
			}, null);
			setSelectedTrainer(trainer || selectedTrainer);
		}
	}, [trainers]);

	const isUpdateForm = useMemo(
		() => window.location.href.indexOf('uuid=') > -1,
		[],
	);

	const loadSession = async () => {
		const { uuid } = queryString.parse(location.search);

		const { data } = await getSession({ uuid });

		const {
			name,
			sessionType: mindfulnessSessionType,
			category,
			description,
			order,
		} = data;
		setSessionToUpdate(data);
		setSessionType(mindfulnessSessionType);
		setValue('filename', name);
		setValue('category', categoryNameDictionary[category]);
		setValue('description', description);
		setValue('order', order);
		setValue('sessionType', mindfulnessSessionType);
	};

	useEffect(() => {
		const hasToUpdate = window.location.href.indexOf('uuid=') > -1;

		if (hasToUpdate) {
			loadSession();
		}

		loadTrainers();
	}, []);

	const getFile = (filelist) => {
		const files = filelist;

		if (files && files.length > 0) {
			const file = files[0];
			return file;
		}

		return null;
	};

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			let {
				filename,
				category,
				mindfulnessSession,
				thumbnailFile,
				description,
				order,
			} = data;

			let duration = 0;

			if (+sessionType === SessionTypes.AUDIO) {
				thumbnailFile = getFile(thumbnailFile);
				mindfulnessSession = getFile(mindfulnessSession);
				const {
					data: { url: thumbnailURL, targetFileName: thumbnailTargetFileName },
				} = await getSessionFileSignedURL({
					filename: thumbnailFile.name,
				});
				await uploadFileToURL({ url: thumbnailURL, file: thumbnailFile });
				thumbnailFile = thumbnailTargetFileName;

				const {
					data: { url: sessionFileURL, targetFileName: sessionTargetFileName },
				} = await getSessionFileSignedURL({
					filename: mindfulnessSession.name,
				});
				await uploadFileToURL({
					url: sessionFileURL,
					file: mindfulnessSession,
				});
				mindfulnessSession = sessionTargetFileName;
			}

			const formdata = new FormData();
			formdata.append('name', filename);
			formdata.append('sessionType', sessionType);
			formdata.append('category', categoryIDDictionary[category]);
			formdata.append('description', description);
			formdata.append('order', order);
			formdata.append('duration', duration);
			formdata.append('sessionFileTargetName', mindfulnessSession);
			formdata.append('thumbnailTargetFileName', thumbnailFile);
			formdata.append('trainerUuid', selectedTrainer.uuid);

			const {
				user: { token },
			} = window.store.getState();

			const response = await fetch(
				`${UCARDIA_API_BASE_URL}${SAVE_MINDFULNESS_SESSION}`,
				{
					method: 'POST',
					body: formdata,
					headers: {
						Authorization: `${token}`,
					},
				},
			);

			if (response.status >= 200 || response.status <= 399) {
				alert('record added successfully.');
				reset();
				setSelectedTrainer({ FirstName: '', uuid: '' });
			} else {
				alert('Error! Failed to add record. Try again.');
			}
			// };
		} catch (error) {
			alert(error.message || 'Something went wrong. Try again later!');
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmitUpdate = async (data) => {
		setIsLoading(true);
		try {
			const { filename, category, description, order } = data;
			const { uuid } = queryString.parse(location.search);

			const response = await updateSession({
				body: {
					uuid,
					filename,
					category: categoryIDDictionary[category],
					description,
					order,
					trainerUuid: selectedTrainer.uuid,
				},
			});

			alert('Successfully updated');
			history.goBack();
		} catch (error) {
			alert(error.message || 'something went wrong. Try again later!');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div
				className="container mx-auto px-4 h-full"
				onClick={() => setIsTrainerSelectVisible(false)}
			>
				<audio hidden id="dummy-audio" />
				<video hidden controls id="dummy-video" />

				<div className="flex content-center items-center justify-center h-full">
					<div className="px-4">
						<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
							<div className="py-2 bold" style={{ textAlign: 'center' }}>
								{isUpdateForm ? 'Update a session' : 'Add a new session'}
							</div>

							{
								<div className="flex-auto px-4 lg:px-10 py-10 pt-0 mt-2">
									<form
										onSubmit={handleSubmit(
											!isUpdateForm ? onSubmit : onSubmitUpdate,
										)}
									>
										<div className="row">
											<div className="px-4 flex-1 column">
												<div className="relative w-full mb-3">
													<label
														className="block uppercase text-gray-700 text-xs font-bold mb-2"
														htmlFor="grid-password"
													>
														File Name
													</label>

													<input
														type="text"
														name="filename"
														className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
														placeholder="File Name"
														ref={register({
															required: {
																value: true,
																message: 'The name of the file is required.',
															},
														})}
													/>

													<ErrorMessage
														errors={errors}
														name="filename"
														as={FormFieldErrorMessage}
													/>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="px-4 flex-1 column">
												<div className="relative w-full mb-3">
													<label
														className="block uppercase text-gray-700 text-xs font-bold mb-2"
														htmlFor="grid-password"
													>
														Description
													</label>

													<input
														type="text"
														name="description"
														className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
														placeholder="Description"
														ref={register({
															required: {
																value: true,
																message:
																	'The description of the session is required.',
															},
														})}
													/>

													<ErrorMessage
														errors={errors}
														name="description"
														as={FormFieldErrorMessage}
													/>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="px-4 flex-1 column">
												<div className="relative w-full mb-3">
													<label
														className="block uppercase text-gray-700 text-xs font-bold mb-2"
														htmlFor="grid-password"
													>
														Order
													</label>

													<input
														type="number"
														name="order"
														className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
														placeholder="Order"
														ref={register({
															required: {
																value: true,
																message:
																	'The order of the session is required.',
															},
														})}
													/>

													<ErrorMessage
														errors={errors}
														name="order"
														as={FormFieldErrorMessage}
													/>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="px-4 flex-1 column">
												<div className="relative w-full mb-3">
													<label
														className="block uppercase text-gray-700 text-xs font-bold mb-2"
														htmlFor="grid-password"
													>
														Trainer
													</label>

													<input
														type="text"
														name="trainer"
														className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
														placeholder="Trainer"
														ref={register({
															required: {
																value: true,
																message: 'Please select a trainer.',
															},
														})}
														list="trainers"
														value={selectedTrainer.firstName}
														readOnly
														onClick={(e) => {
															e.stopPropagation();
															setIsTrainerSelectVisible(true);
														}}
													/>

													{isTrainerSelectVisible && (
														<div
															style={{
																backgroundColor: '#fff',
																padding: '5px 5px',
															}}
														>
															<input
																style={{ border: 'solid 1px #000' }}
																onChange={filterTrainers}
																onClick={(e) => e.stopPropagation()}
																placeholder="Search trainers"
															/>

															{filteredTrainers.length > 0 &&
																filteredTrainers.map((trainer) => {
																	const { firstName: trainerName, uuid } =
																		trainer;

																	return (
																		<div
																			key={uuid}
																			onClick={() => {
																				setSelectedTrainer(trainer);
																				setIsTrainerSelectVisible(false);
																			}}
																		>
																			{trainerName}
																		</div>
																	);
																})}

															{filteredTrainers.length === 0 &&
																trainerToSearch !== '' && (
																	<div className="cursor-pointer">
																		Not Found.
																	</div>
																)}
														</div>
													)}

													<ErrorMessage
														errors={errors}
														name="trainer"
														as={FormFieldErrorMessage}
													/>
												</div>
											</div>
										</div>

										{!isUpdateForm && (
											<>
												{+sessionType === SessionTypes.AUDIO ? (
													<div className="row">
														<div className="px-4 flex-1 column">
															<div className="relative w-full mb-3">
																<label
																	className="block uppercase text-gray-700 text-xs font-bold mb-2"
																	htmlFor="grid-password"
																>
																	Thumbnail
																</label>

																<input
																	type="file"
																	name="thumbnailFile"
																	className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
																	placeholder="Thumbnail URL"
																	ref={register}
																/>

																<ErrorMessage
																	errors={errors}
																	name="thumbnailFiles"
																	as={FormFieldErrorMessage}
																/>
															</div>
														</div>
													</div>
												) : (
													''
												)}

												<div className="row">
													<div className="px-4 flex-1 column">
														<div className="relative w-full mb-3">
															<label
																className="block uppercase text-gray-700 text-xs font-bold mb-2"
																htmlFor="grid-password"
															>
																{+sessionType === SessionTypes.AUDIO
																	? 'Audio Session'
																	: 'Video URL'}
															</label>

															<input
																type={
																	+sessionType === SessionTypes.AUDIO
																		? 'file'
																		: 'text'
																}
																name="mindfulnessSession"
																className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
																placeholder={`${
																	+sessionType === SessionTypes.AUDIO
																		? 'Audio Session'
																		: 'Video URL'
																}`}
																ref={register({
																	required: {
																		value: true,
																		message: 'This field is required!',
																	},
																})}
																onChange={(e) =>
																	setVimeoVideoUrl(e.target.event)
																}
															/>

															<ErrorMessage
																errors={errors}
																name="sessionFiles"
																as={FormFieldErrorMessage}
															/>
														</div>
													</div>
												</div>
											</>
										)}

										<div className="row">
											<div className="px-4 flex-1 column">
												<div className="relative w-full mb-3">
													<label
														className="block uppercase text-gray-700 text-xs font-bold mb-2"
														htmlFor="grid-password"
													>
														Select Category
													</label>

													<select name="category" ref={register}>
														<option value="mindful">Mindful</option>
														<option value="nature">Nature</option>
														<option value="breathe">Breathe</option>
													</select>

													<ErrorMessage
														errors={errors}
														name="sessionFiles"
														as={FormFieldErrorMessage}
													/>
												</div>
											</div>
										</div>

										<div className="row">
											<div className="px-4 flex-1 column">
												<div className="relative w-full mb-3">
													<label
														className="block uppercase text-gray-700 text-xs font-bold mb-2"
														htmlFor="grid-password"
													>
														Select Session Type
													</label>

													<select
														name="sessionType"
														ref={register}
														onChange={(e) => {
															setSessionType(e.target.value);
														}}
														disabled={isUpdateForm}
														value={sessionType}
														style={{
															cursor: isUpdateForm ? 'not-allowed' : 'default',
														}}
													>
														<option value={SessionTypes.AUDIO}>Audio</option>
														<option value={SessionTypes.NON_LIVE_VIDEO}>
															Video (Regular)
														</option>
														<option value={SessionTypes.VIDEO}>
															Video (Live Session)
														</option>
													</select>

													<ErrorMessage
														errors={errors}
														name="sessionFiles"
														as={FormFieldErrorMessage}
													/>
												</div>
											</div>
										</div>

										<div className="text-center mt-6">
											<button
												className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
												type="submit"
												disabled={isLoading}
											>
												{isUpdateForm ? 'Update' : 'Submit'}
											</button>
										</div>
									</form>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UploadSession;
