import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { signinAdmin } from '../../api';
import { saveUserAuth } from '../../store/actions/user';
import { setCookie } from 'utils';

const Login = ({ saveUserAuthAction }) => {
	const { register, handleSubmit, errors } = useForm();
	const history = useHistory();
	const onSubmit = async (data) => {
		try {
			const {
				data: { user, token },
			} = await signinAdmin(data);

			setCookie({ key: 'access_token', value: token });
			setCookie({ key: 'u', value: JSON.stringify(user) });
			saveUserAuthAction({ user, token });
			history.replace('/admin/users');
		} catch (e) {
			alert(e.message || 'Something went wrong. Try again later or if the problem persists then please contact Admin!');
		}
	};

	return (
		<>
			<div className="container mx-auto px-4 h-full">
				<div className="flex content-center items-center justify-center h-full">
					<div className="w-full lg:w-4/12 px-4">
						<div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
							<div className="rounded-t mb-0 px-6 py-6">
								{/* <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-sm font-bold">
                    Sign in with
                  </h6>
                </div>
                <div className="btn-wrapper text-center">
                  <button
                    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg")}
                    />
                    Github
                  </button>
                  <button
                    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg")}
                    />
                    Google
                  </button>
                </div> */}
								{/* <hr className="mt-6 border-b-1 border-gray-400" /> */}
							</div>

							<div className="flex-auto px-4 lg:px-10 py-10 pt-0">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="relative w-full mb-3">
										<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
											Email
										</label>
										<input
											type="email"
											name="emailAddress"
											className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
											placeholder="example@ucardia.com"
											ref={register({ required: { value: true, message: 'Email address is required.' } })}
										/>
										<ErrorMessage errors={errors} name="emailAddress" />
									</div>

									<div className="relative w-full mb-3">
										<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
											Password
										</label>
										<input
											type="password"
											name="password"
											className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
											placeholder="Password"
											ref={register({
												required: { value: true, message: 'Password is required.' },
											})}
										/>
										<ErrorMessage errors={errors} name="password" />
									</div>
									{/* <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox text-gray-800 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-gray-700">
                        Remember me
                      </span>
                    </label>
                  </div> */}

									<div className="text-center mt-6">
										<button
											className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
											type="submit"
										>
											Sign In
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="flex flex-wrap mt-6 relative">
							{/* <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-gray-300"
                >
                  <small>Forgot password?</small>
                </a>
              </div> */}
							<div className="w-1/2">
								<Link to="/auth/register" className="text-gray-300">
									<small>Create new account</small>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapActionsToProps = { saveUserAuthAction: saveUserAuth };

export default connect(null, mapActionsToProps)(Login);
