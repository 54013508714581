import React from 'react';
import './index.css';

const FormFieldErrorMessage = ({ children }) => {
	return (
		<p className="error-text" style={{ color: 'red' }}>
			{children}
		</p>
	);
};

export default FormFieldErrorMessage;
