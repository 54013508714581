import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// components

import Navbar from 'components/Navbars/AuthNavbar.js';
import FooterSmall from 'components/Footers/FooterSmall.js';

// views

import Login from '../views/auth/Login.js';
import Register from '../views/auth/Register.js';
import { getCookie } from 'utils.js';

export default function Auth() {
	const [isLoading, setIsLoading] = useState(true);
	const [token, setToken] = useState(null);

	useEffect(() => {
		const tokenCookie = getCookie({ key: 'access_token' });

		if (tokenCookie && tokenCookie !== '') {
			setToken(tokenCookie);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	}, []);

	return (
		<>
			<Navbar transparent />
			<main>
				<section className="relative w-full h-full py-30 min-h-screen">
					<div
						className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
						style={{
							backgroundImage: 'url(' + require('assets/img/register_bg_2.png') + ')',
						}}
					></div>
					<div>Please wait. Loading...</div>
					{!isLoading && !token && (
						<Switch>
							<Route path="/auth/login" exact component={Login} />
							<Route path="/auth/register" exact component={Register} />

							<Redirect from="/auth" to="/auth/login" />
						</Switch>
					)}
					{!isLoading && token && <Redirect from="/auth" to="/admin" />}
					{/* <FooterSmall absolute /> */}
				</section>
			</main>
		</>
	);
}
