import React from 'react';

const AddPatientAdditionalInfo = ({
	register,
	errors,
	ErrorMessage,
	FormFieldErrorMessage,
	otherMedicalConditions,
	setOtherMedicalConditions,
}) => {
	return (
		<div className="flex flex-col">
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Reason For Cardiac Rehab
							</label>
							<select
								name="cardiacRehabReason"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="Acute coronary artery syndrome">
									Acute coronary artery syndrome
								</option>
								<option key="2" value="Angioplasty">
									Angioplasty
								</option>
								<option key="3" value="Cardiac transplantation">
									Cardiac transplantation
								</option>
								<option key="4" value="Chronic obstructive pulmonary disease (COPD)">
									Chronic obstructive pulmonary disease (COPD)
								</option>
								<option key="5" value="Chronic stable angina">
									Chronic stable angina
								</option>
								<option key="6" value="Congestive heart failure (CHF)">
									Congestive heart failure (CHF)
								</option>
								<option key="7" value="Coronary artery bypass surgery (CABG)">
									Coronary artery bypass surgery (CABG)
								</option>
								<option key="8" value="Myocardial infarction (MI)">
									Myocardial infarction (MI)
								</option>
								<option key="9" value="Percutaneous coronary intervention (PCI)">
									Percutaneous coronary intervention (PCI)
								</option>
								<option key="10" value="Stent">
									Stent
								</option>
								<option key="11" value="Valve repair/replacement">
									Valve repair/replacement
								</option>
								<option key="12" value="Other">
									Other
								</option>
							</select>
							<ErrorMessage errors={errors} name="cardiacRehabReason" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Date of Cardiac Incident
							</label>
							<input
								type="date"
								name="cardiacIncidentDate"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline width-125 ease-linear transition-all duration-150"
								// placeholder="John"
								ref={register({ required: { value: true, message: 'Date is required.' } })}
							/>
							<ErrorMessage errors={errors} name="cardiacIncidentDate" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
			</div>
			<div className="flex-item">
				<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Patient on Oxygen
							</label>
							<select
								name="isOnSupplementalOxygen"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="true">
									Yes
								</option>
								<option key="2" value="false">
									No
								</option>
							</select>
							<ErrorMessage errors={errors} name="isOnSupplementalOxygen" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3 width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Prescribed Oxygen Flow
							</label>
							<input
								type="name"
								name="oxygenFlow"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="Prescribed Oxygen Flow"
								ref={register({ required: { value: true, message: 'Prescribed Oxygen Flow is required.' } })}
							/>
							<ErrorMessage errors={errors} name="oxygenFlow" as={FormFieldErrorMessage} />
						</div>
					</div>
					</div>
					<div className="flex flex-row">
					<div className="flex-item width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Smoking Status
							</label>
							<select
								name="smokingStatus"
								ref={register}
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 drop-down-padding"
							>
								<option key="1" value="Current">
									Current
								</option>
								<option key="2" value="Former">
									Former
								</option>
								<option key="2" value="Never">
									Never
								</option>
							</select>
							<ErrorMessage errors={errors} name="smokingStatus" as={FormFieldErrorMessage} />
						</div>
					</div>
					<div className="flex-item ml-3 width-50">
						<div className="relative w-full mb-3">
							<label className="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
								Pack years
							</label>
							<input
								type="number"
								name="packYears"
								className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
								placeholder="Prescribed Oxygen Flow"
								ref={register({ required: { value: true, message: 'Prescribed Oxygen Flow is required.' } })}
							/>
							<ErrorMessage errors={errors} name="packYears" as={FormFieldErrorMessage} />
						</div>
					</div>
				</div>
				
				<div className="flex-item">
					<h1 className="text-center">Other Medical Conditions</h1>
				</div>
				<div className="flex-item mt-2">
					<div className="flex flex-row">
						<div className="flex-item width-75">Chronic kidney disease (CKD)</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.chronicKidneyDisease ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, chronicKidneyDisease: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' +
									(otherMedicalConditions.chronicKidneyDisease === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, chronicKidneyDisease: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Chronic Obstructive Pulmonary Disease (COPD)</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={
									'tick-wrapper ' + (otherMedicalConditions.chronicObstructivePulmonaryDisease ? 'tick-checked' : '')
								}
								onClick={() =>
									setOtherMedicalConditions({ ...otherMedicalConditions, chronicObstructivePulmonaryDisease: true })
								}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' +
									(otherMedicalConditions.chronicObstructivePulmonaryDisease === false ? 'cross-chechked' : '')
								}
								onClick={() =>
									setOtherMedicalConditions({ ...otherMedicalConditions, chronicObstructivePulmonaryDisease: false })
								}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Congestive Heart Failure (CHF)</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.congestiveHeartFailure ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, congestiveHeartFailure: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' +
									(otherMedicalConditions.congestiveHeartFailure === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, congestiveHeartFailure: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">COVID-19</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.covid19 ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, covid19: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={'ml-3 cross-wrapper ' + (otherMedicalConditions.covid19 === false ? 'cross-chechked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, covid19: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Taking Insulin?</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.insulin ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, insulin: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={'ml-3 cross-wrapper ' + (otherMedicalConditions.insulin === false ? 'cross-chechked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, insulin: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Diabetes</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.diabetes ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, diabetes: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={'ml-3 cross-wrapper ' + (otherMedicalConditions.diabetes === false ? 'cross-chechked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, diabetes: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Hyperlipidermia</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.hyperlipidemia ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, hyperlipidemia: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' + (otherMedicalConditions.hyperlipidemia === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, hyperlipidemia: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Hypertension</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.hypertension ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, hypertension: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' + (otherMedicalConditions.hypertension === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, hypertension: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Osteoarthritis</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.osteoarthritis ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, osteoarthritis: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' + (otherMedicalConditions.osteoarthritis === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, osteoarthritis: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Osteoperosis</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.osteoperosis ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, osteoperosis: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' + (otherMedicalConditions.osteoperosis === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, osteoperosis: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Fallen in the last 3 months?</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.isFallen ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, isFallen: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' + (otherMedicalConditions.isFallen === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, isFallen: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Dizziness, fainting, or lightheadedness in the last 3 months?</div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.dizziness ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, dizziness: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' + (otherMedicalConditions.dizziness === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, dizziness: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
				<div className="flex-item mt-3">
					<div className="flex flex-row">
						<div className="flex-item width-75">Use an assistive device for walking (cane or walker)? </div>
						<div className="flex-item width-25 pl-4">
							<span
								className={'tick-wrapper ' + (otherMedicalConditions.assistiveWalking ? 'tick-checked' : '')}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, assistiveWalking: true })}
							>
								<i className="fas fa-check"></i>
							</span>
							<span
								className={
									'ml-3 cross-wrapper ' + (otherMedicalConditions.assistiveWalking === false ? 'cross-chechked' : '')
								}
								onClick={() => setOtherMedicalConditions({ ...otherMedicalConditions, assistiveWalking: false })}
							>
								<i className="fas fa-times"></i>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddPatientAdditionalInfo;
