import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import FormFieldErrorMessage from 'components/FormFieldErrorMessage';
import {
	createOrganization,
	getMindfulnessSessionFilePreSignedURL,
	saveMindfulnessSession,
	uploadFileToURL,
} from '../../api';

import './UploadMindfulnessSession.css';
import { UCARDIA_API_BASE_URL } from 'config';
import { SAVE_MINDFULNESS_SESSION } from 'api/endpoints';

const categoryIDDictionary = {
	mindful: 0,
	nature: 1,
	breathe: 2,
};

const Media = ({}) => {
	const { register, handleSubmit, errors, reset } = useForm();
	const [isLoading, setIsLoading] = useState(false);
	const [isAudioSelected, setIsAudioSelected] = useState(null);
	const [image, setImage] = useState(null);
	const [sessionFile, setSessionFile] = useState(null);

	const onSelectSessionType = (isSelected) => {
		setIsAudioSelected(isSelected);
	};

	const getFile = (filelist) => {
		const files = filelist;

		if (files && files.length > 0) {
			const file = files[0];
			return file;
		}

		return null;
	};

	const onSubmit = async (data) => {
		setIsLoading(true);
		try {
			const { filename, category, sessionFiles, thumbnailFiles } = data;
			// const { data: sessionFilePreSignedURL } = await getMindfulnessSessionFilePreSignedURL({ filename });
			// const { data: thumbnailFilePreSignedURL } = await getMindfulnessSessionFilePreSignedURL({
			// 	filename: `${filename}Thumbnail`,
			// });
			const sessionFile = getFile(sessionFiles);
			const thumbnailFile = getFile(thumbnailFiles);

			const formdata = new FormData();
			formdata.append('sessionFile', sessionFile);
			formdata.append('thumbnailFile', thumbnailFile);
			formdata.append('name', filename);
			formdata.append('sessionType', isAudioSelected ? 0 : 1);
			formdata.append('category', categoryIDDictionary[category]);

			// await saveMindfulnessSession(formdata);

			const {
				user: { token },
			} = window.store.getState();
			console.log(formdata);

			const response = await fetch(`${UCARDIA_API_BASE_URL}${SAVE_MINDFULNESS_SESSION}`, {
				method: 'POST',
				body: formdata,
				headers: {
					Authorization: `${token}`,
				},
			});

			// const sessionFileUploadResponse = await uploadFileToURL({ file: sessionFile, url: sessionFilePreSignedURL });
			// const thumbnailUploadResponse = await uploadFileToURL({ file: thumbnailFile, url: thumbnailFilePreSignedURL });

			// await saveMindfulnessSession({
			// 	name: filename,
			// 	fileURL: sessionFilePreSignedURL,
			// 	thumbnailURL: thumbnailFilePreSignedURL,
			// 	sessionType: isAudioSelected ? 0 : 1,
			// 	category: categoryIDDictionary[category],
			// });
			reset();
		} catch (error) {
			alert(error.message || 'Something went wrong. Try again later!');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<div className="container mx-auto px-4 h-full">
				<div className="flex content-center items-center justify-center h-full">
					<div className="px-4">
						<video width="320" height="240" controls>
							<source
								src="https://storage.googleapis.com/ucardia_mindfulness_sessions_bucket/Week_2__ex.mov?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=storage-ucardia%40ucardia-297520.iam.gserviceaccount.com%2F20210213%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20210213T082005Z&X-Goog-Expires=3600&X-Goog-SignedHeaders=host&X-Goog-Signature=70fe321a33e20936731cd59863a24dc3b8edb90b6adf1858da977ac6374c270a64cd69885c86bd5cb0c2b6aa7b31db2fa0648252c8cc67843dead7290329617f072a94c435ce6049e83b2e731fab8dc0e54bc735e0d2e1f9d21ef769a4ef560022ad7c6219800fedd5dd0e56299622718013150c1dcf3583da254da7cac6c0f5606d23de4366c28c40f1dc7ecdafc568e909322f31f6eeceb3a29a2aeadbf460ad7010681f730d6663a431ca7f07582a7ec82d9df8ef1d8b923be1218858ce486634f9d21c7f1afc2db6180b9c593e6faa605053afda4d28b32f23f8b4f222c693cdf20aa637b111de42a66c4af10a76d8961888419e69b940af6175c7c7c3a7"
								type="video/mp4"
							/>
						</video>
					</div>
				</div>
			</div>
		</>
	);
};

export default Media;
